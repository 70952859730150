<template>
    <path d="m173.9 374.3-166.4-166.4c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0l112.1 112.1 240.1-240.1c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2l-294.4 294.4c-10 10-26.2 10-36.2 0z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 382 });
    },
    emits: ['dimensions'],
};
</script>
